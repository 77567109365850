import Button from "@mui/material/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import ActivateCamera from "../components/ActivateCamera";
import { PrepareChangingMmu } from "../components/PrepareChangingMmu";

const BarcodeReader: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <h3>
        MMUの交換を実施します。
        <br />
        新しいMMUの一次元バーコードを
        <br />
        読み取ってください。
      </h3>
      <ActivateCamera />
      <div className="readingResult">
        <h3>読み取ったシリアル番号はこちら。</h3>
        <p id="result"></p>
      </div>
      <h3>
        シリアル番号が読み取れたら下のボタンを押してください。
        <br />
        MMU交換前の処理を実施します。
      </h3>
      <Button
        variant="contained"
        onClick={() => {
          PrepareChangingMmu(navigate);
        }}
      >
        MMU交換前処理実施
      </Button>
    </div>
  );
};

export default BarcodeReader;
