import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { EmitRadioWaves } from "../components/EmitRadioWaves";
import { useLocation } from "react-router-dom"
import { MmuReplaceParams } from "../components/Utils";



const EmitRadioWavesPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state as MmuReplaceParams;
  return (
    <div>
      <h3>交換準備完了！</h3>
      <h3>MMUの交換作業を実施してください。</h3>

      <h3>交換が完了したら電波発射ボタンを押してください。</h3>
      <Button
        variant="contained"
        onClick={() => {
          EmitRadioWaves(navigate, params.neid, params.ruid, params.cellNum, params.serialNumber);
        }}
      >
        電波発射ボタン
      </Button>
    </div>
  );
};

export default EmitRadioWavesPage;
