import React from "react";

const Complete: React.FC = () => {
  return (
    <div>
      <h3>交換作業終了です！  <br />
        正常に電波発射ができましたので、退局してください。<br />
        ご対応ありがとうございました！</h3>
    </div>
  );
};

export default Complete;