import React from "react";

const StandByCompletePage: React.FC = () => {

  return (
    <div>
      <h3>電波発射処理を実施中、、、</h3>
      <h3>画面を閉じずに10分ほどお待ちください。</h3>
    </div>
  );
};

export default StandByCompletePage;