import { NavigateFunction } from "react-router-dom";
import { getAlarmOccurrence } from "../api/GetAlarmOccurrence";
import { getHealthStatus } from "../api/GetHealthStatus";
import { postChangeSerialNumber } from "../api/PostChangeSerialNumber";
import { postLockCell } from "../api/PostLockCell";
import { getIsLatestSwVersion } from "../api/GetIsLatestSwVersion";
import { sleep } from "./Utils";

export const EmitRadioWaves = async (
  navigate: NavigateFunction,
  neid: string,
  ruid: string,
  cellNum: string,
  serialNumber: string
) => {
  try {

    // 電波発射処理中の画面に遷移
    navigate("/StandByCompletePage");

    // シリアルナンバーの変更を実施
    if (serialNumber && neid && ruid) {
      await postChangeSerialNumber(neid, ruid, serialNumber);
      console.log(
        "シリアルナンバー変更が完了しました。8分後より「oran-communication-fail」アラームが解消しているかの確認をします。"
      );
      await sleep(480);
    } else {
      throw new Error(
        `シリアルナンバーかneidかruidの取得に失敗しました neid: ${neid} ruid: ${ruid}  serialNumber: ${serialNumber}`
      );
    }

    // 「oran-communication-fail」アラームが消えるまで確認するループ
    let isOccurrence = true;
    let count = 0;
    while (isOccurrence) {
      await sleep(60);
      isOccurrence = await getAlarmOccurrence(neid, ruid);
      ++count;
      // 暫定対処として、もしあまりにも取得できなかったらループから抜ける
      if (count === 5) {
        throw new Error("時間内にアラームが解消しませんでした");
      }
    }
    console.log(
      "「oran-communication-fail」アラームが解消しました。SWバージョン確認を実施します。"
    );

    // SWバージョンが最新かどうか確認
    const isLatestSwVersion = await getIsLatestSwVersion(neid, ruid);

    if (isLatestSwVersion.is_latest_sw_version) {
      console.log("交換したMMUのSWバージョンは最新です。セル閉塞解除を実施します。");
    } else {
      console.warn("【注意】交換したMMUのSWバージョンは最新ではありません。セル閉塞解除を実施します。");
    }

    // セル閉塞解除実行
    if (cellNum) {
      await postLockCell(neid, cellNum, "unlocked");
    } else {
      throw new Error(`cellNumの取得に失敗しました cellNum: ${cellNum} `);
    }
    console.log("セル閉塞解除完了しました。正常性確認を実施します。");

    // 正常性確認を実行
    let isClearedHealthCheck = false;
    let healthCheckCount = 0;
    while (!isClearedHealthCheck) {
      await sleep(60);
      const healthStatus = await getHealthStatus(neid, cellNum, ruid);
      ++healthCheckCount;
      if (
        healthStatus.is_ok_v_du === true &&
        healthStatus.is_ok_cell === true &&
        healthStatus.is_ok_o_ru === true &&
        healthStatus.is_ok_time_synchro === true
      ) {
        console.log("正常性確認完了しました。", healthStatus);
        isClearedHealthCheck = true
      } else {
        if(healthCheckCount === 5){
          throw new Error(`時間内に正常性確認が終了しませんでした。 healthStatus: ${healthStatus}`);
        }
      }
    }


    // 正常性確認が終了したら最後の画面に遷移
    navigate("/Complete");
  } catch (error) {
    console.log("電波発射作業中にエラーが発生しました", error);
    alert("電波発射作業中に失敗しました");
  }
};
