import { NavigateFunction } from "react-router-dom";
import { postLockCell } from "../api/PostLockCell";

export const PrepareChangingMmu = async (
  navigate: NavigateFunction
) => {
  try {
    // TODO どこから情報をとるのか検討
    const neid = "112297";
    const cellNum = "0";
    const ruid = "0";

    const serialNumber = document.getElementById("result")?.innerText
    
    navigate("/PrepareChangingMmu");

    // セル閉塞実行
    await postLockCell(neid, cellNum, "locked");
    console.log(
      "セル閉塞完了しました。"
    );


    // アラームが取得できたら電源OFF画面にページ遷移
    navigate("/PowerOffMmu", { state: { neid: neid, cellNum: cellNum, ruid: ruid, serialNumber: serialNumber }});
  } catch (error) {
    console.log("MMU交換前処理中にエラーが発生しました", error)
    alert("MMU交換前処理に失敗しました");
  }
};
