export interface StartExecutionResponse {
  data: {
    executionArn: string;
    startDate: number;
  };
  status: number;
  statusText: string;
  headers: Object;
  config: Object;
  request: Object;
}

export interface MmuReplaceParams{
  neid: string;
  ruid: string;
  cellNum: string;
  serialNumber: string;
}

export const sleep = (second: number) =>
  new Promise((resolve) => setTimeout(resolve, second * 1000));
