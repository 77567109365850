import axios from "axios";
import { sleep } from "../components/Utils";
import { apiUrlMiruruBe } from "../consts/variables_dev";

interface ApiStatusResponse {
  data: {
    executionArn: string;
    input: string;
    inputDetails: { __type: string; included: boolean };
    name: string;
    output: string;
    outputDetails: { __type: string; included: boolean };
    redriveCount: number;
    redriveStatus: string;
    redriveStatusReason: string;
    startDate: number;
    stateMachineArn: string;
    status: string;
    stopDate: number;
    traceHeader: string;
  };
  status: number;
  statusText: string;
  headers: Object;
  config: Object;
  request: Object;
}

export interface CrogResponse {
  statusCode: number;
  body: string;
}

const getResult = async (
  executionArn: String,
  resultRes: CrogResponse = { statusCode: 0, body: "" }
) => {
  while (resultRes.statusCode === 0) {
    await sleep(1);
    const res: ApiStatusResponse = await axios.post(
      `${apiUrlMiruruBe}/status`,
      {
        executionArn: executionArn,
      }
    );
    if (res.status !== 200) {
      throw new Error("結果を取得するAPIの実行に失敗したよ");
    }
    if (res.data.output) {
      resultRes = JSON.parse(res.data.output);
    }
  }
  return resultRes;
};

export { getResult };
