import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { PowerOffMmu } from "../components/PowerOffMmu";
import { useLocation } from "react-router-dom"
import { MmuReplaceParams } from "../components/Utils";



const EmitRadioWavesPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state as MmuReplaceParams;
  return (
    <div>
      <h3>MMU電源OFF作業をお願いします。</h3>
      <h3>完了したら下記ボタンを押下してください。</h3>

      <Button
        variant="contained"
        onClick={() => {
          PowerOffMmu(navigate, params.neid, params.ruid, params.cellNum, params.serialNumber);
        }}
      >
        電源OFF完了
      </Button>
    </div>
  );
};

export default EmitRadioWavesPage;
