// 必要なモジュールをインポート
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import BarcodeReader from "./pages/BarcodeReader";
import PowerOffMmu from "./pages/PowerOffMmu";
import EmitRadioWavesPage from "./pages/EmitRadioWavesPage";
import Header from "./pages/Header";
import PrepareChangingMmu from "./pages/PrepareChangingMmu";
import Complete from "./pages/Complete";
import StandByCompletePage from "./pages/StandByComplete";

// Appコンポーネント内でルーティングを設定
const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route index element={<BarcodeReader />} />
        <Route path="PrepareChangingMmu" element={<PrepareChangingMmu />} />
        <Route path="PowerOffMmu" element={<PowerOffMmu />} />
        <Route path="EmitRadioWavesPage" element={<EmitRadioWavesPage />} />
        <Route path="StandByCompletePage" element={<StandByCompletePage />} />
        <Route path="Complete" element={<Complete />} />
        <Route path="*" element={<p>Page Not Found</p>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
