import React from "react";

const PrepareChangingMmu: React.FC = () => {

  return (
    <div>
      <h3>交換前の処理を実施中、、、</h3>
      <h3>交換可能になるまでお待ちください。</h3>
    </div>
  );
};

export default PrepareChangingMmu;
