import { NavigateFunction } from "react-router-dom";
import { getAlarmOccurrence } from "../api/GetAlarmOccurrence";
import { sleep } from "./Utils";

export const PowerOffMmu = async (
  navigate: NavigateFunction,
  neid: string,
  ruid: string,
  cellNum: string,
  serialNumber: string
) => {
  try {
    
    navigate("/PrepareChangingMmu");


    // セル閉塞成功したら「oran-communication-fail」アラームが取得できるまでループ
    let isOccurrence = false;
    let count = 1;
    while (!isOccurrence) {
      await sleep(60);
      isOccurrence = await getAlarmOccurrence(neid, ruid);
      ++count;
      // 暫定対処として、もしあまりにも取得できなかったらループから抜ける
      if (count === 5) {
        throw new Error("時間内にアラームが発生しませんでした");
      }
    }
    console.log(
      "「oran-communication-fail」アラームが発生していることを確認できました。"
    );  

    // アラームが取得できたら電波発射画面にページ遷移
    navigate("/EmitRadioWavesPage", { state: { neid: neid, cellNum: cellNum, ruid: ruid, serialNumber: serialNumber }});
  } catch (error) {
    console.log("MMU交換前処理中にエラーが発生しました", error)
    alert("MMU交換前処理に失敗しました");
  }
};
