import axios from "axios";
import { StartExecutionResponse } from "../components/Utils";
import { apiUrlMiruruBe } from "../consts/variables_dev";
import { CrogResponse, getResult } from "./GetApiResult";

interface IsLatestSwVersionBody {
    is_latest_sw_version: boolean;
    err_msg: string;
}

const getIsLatestSwVersion = async (neid: string, ruid: string) => {
  try {
    // apiの実行
    const res: StartExecutionResponse = await axios.get(
      `${apiUrlMiruruBe}/o_ru/${neid}/${ruid}/check_sw_version`
    );

    if (res.status !== 200) {
      throw new Error("`最新SWバージョン確認APIの実行に失敗したよ");
    }

    // apiの結果を取得
    const resultRes: CrogResponse = await getResult(res.data.executionArn);

    const resultResBody: IsLatestSwVersionBody = JSON.parse(resultRes.body);

    return resultResBody;

  } catch (e) {
    console.log("apiリクエスト中にエラーが発生", e);
    throw new Error("最新SWバージョン確認に失敗したよ");
  }
};

export { getIsLatestSwVersion };
