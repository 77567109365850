import Quagga from "@ericblade/quagga2";
import { useEffect, useRef } from "react";
import "./ActivateCamera.css";

const ActivateCamera = () => {
  const barcodeRef = useRef(null);
  useEffect(() => {
    if (barcodeRef.current) {
      Quagga.init(
        {
          inputStream: {
            type: "LiveStream",
            target: barcodeRef.current,
          },
          decoder: {
            readers: ["code_39_reader"],
          },
        },
        (err) => {
          if (err) {
            console.log(err);
            return;
          }
          Quagga.start();
        }
      );

      Quagga.onProcessed((result) => {
        if (result == null) return;
        if (typeof result != "object") return;
        if (result.boxes === undefined) return;
        const ctx = Quagga.canvas.ctx.overlay;
        const canvas = Quagga.canvas.dom.overlay;
        ctx.clearRect(
          0,
          0,
          parseInt(String(canvas.width)),
          parseInt(String(canvas.height))
        );
        Quagga.ImageDebug.drawPath(result.boxes, { x: 0, y: 1 }, ctx, {
          color: "blue",
          lineWidth: 5,
        });
      });

      Quagga.onDetected((result) => {
        console.log(result.codeResult.code);
        document.getElementById("result")!.textContent = result.codeResult.code;
      });
    }

    return () => {
      Quagga.stop();
    };
  }, []);

  return (
    <div
      ref={barcodeRef}
      style={{ width: "100%", height: "100%" }}
      className="barcodeReader"
    />
  );
};

export default ActivateCamera;
