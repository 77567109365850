import axios from "axios";
import { StartExecutionResponse } from "../components/Utils";
import { apiUrlMiruruBe } from "../consts/variables_dev";
import { CrogResponse, getResult } from "./GetApiResult";


const postLockCell = async (
  neid: string,
  cellNum: string,
  cellState: string
) => {
  try {
    // apiの実行
    const res: StartExecutionResponse = await axios.post(
      `${apiUrlMiruruBe}/cell/${neid}/lock/${cellNum}/${cellState}`
    );

    if (res.status !== 200) {
      throw new Error("cell閉塞APIの実行に失敗したよ");
    }

    // apiの結果を取得
    const resultRes: CrogResponse = await getResult(res.data.executionArn);

    const resultResStatusCode: number = resultRes.statusCode;
    if (resultResStatusCode !== 200) {
      throw new Error("cell閉塞に失敗したよ");
    }
  } catch (e) {
    console.log("apiリクエスト中にエラーが発生", e);
    throw new Error("cell閉塞に失敗したよ");
  }
};

export { postLockCell };
